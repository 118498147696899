export default {
    pl: {
        nav_about: "O FIRMIE",
        nav_projects: "PROJEKTY",
        nav_clients: "WSPÓŁPRACA",
        nav_contact: "KONTAKT"
    },
    en: {
        nav_about: "ABOUT",
        nav_projects: "PROJECTS",
        nav_clients: "HIRE US",
        nav_contact: "CONTACT"
    }

}