/* eslint-disable max-len */
/* eslint-disable no-console */
import * as lang from './lang';

let initialX = null;
let initialY = null;

function handleOrientationEvent(event) {
  let x = event.beta ? event.beta : event.y * 90;
  let y = event.gamma ? event.gamma : event.x * 90;

  x = Math.floor(x / 2);
  y = Math.floor(y / 2);

  if (x > 50) {
    x = 50;
  } else if (x < -50) {
    x = -50;
  }

  if (y > 50) {
    y = 50;
  } else if (y < -50) {
    y = -50;
  }

  console.log(x, y);

  if (!initialX && !initialY) {
    initialX = x;
    initialY = y;
  } else {
    console.log('change gradient');
    document.getElementById('gradient').style.backgroundPosition = `${50 + x}% ${50 + y}%`;
  }
}

function isEventFired() {
  if (!initialX && !initialY) {
    console.log('Warning: Cannot receive device orientation events, this browser is not supported.');
  }
}

// Webkit en Mozilla variant beide registreren.
window.addEventListener('MozOrientation', handleOrientationEvent, true);
window.addEventListener('deviceorientation', handleOrientationEvent, true);

setTimeout(isEventFired, 2000);

let activeLang = 'pl';

const langBtn = document.getElementById('langBtn');
const langBtn2 = document.getElementById('langBtn2');

function changeLang() {
  if (langBtn.innerHTML == 'PL') {
    activeLang = 'pl';
    langBtn.innerHTML = 'EN';
    langBtn2.innerHTML = 'EN';
  } else if (langBtn.innerHTML == 'EN') {
    activeLang = 'en';
    langBtn.innerHTML = 'PL';
    langBtn2.innerHTML = 'PL';
  }
  refreshLang();
}

langBtn.addEventListener('click', changeLang);
langBtn2.addEventListener('click', changeLang);
const navItems = document.getElementsByTagName('li');

function refreshLang() {
  navItems[0].innerHTML = lang.default[activeLang].nav_about;
  navItems[1].innerHTML = lang.default[activeLang].nav_projects;
  navItems[2].innerHTML = lang.default[activeLang].nav_clients;
  navItems[3].innerHTML = lang.default[activeLang].nav_contact;

  navItems[6].innerHTML = lang.default[activeLang].nav_about;
  navItems[7].innerHTML = lang.default[activeLang].nav_projects;
  navItems[8].innerHTML = lang.default[activeLang].nav_clients;
  navItems[9].innerHTML = lang.default[activeLang].nav_contact;

}

refreshLang();

if( document.readyState !== 'loading' ) {
  hideSpinner();
} else {
  document.addEventListener('DOMContentLoaded', hideSpinner);
}

function hideSpinner() {
  window.setTimeout(() => {
    document.querySelector('.spinner').style.opacity = '0';
    document.querySelector('.spinner').style.visibility = '0';
    document.querySelector('.loading-gradient').style.opacity = '0';
    document.querySelector('.loading-gradient').style.visibility = '0';
    window.setTimeout(() => {
    document.querySelector('.spinner').style.display = 'none';
    }, 400)
  }, 2000);
}

const hamburger = document.getElementById('menuOpen');
const cross = document.getElementById('menuClose');
const sideMenu = document.querySelector('.menu-mobile');
hamburger.addEventListener('click', () => {
  sideMenu.style.transform = 'translateX(0%)';
  sideMenu.style.opacity = '1';
  sideMenu.style.visibility = 'visible';
});

cross.addEventListener('click', () => {
  sideMenu.style.transform = 'translateX(-100%)';
  sideMenu.style.opacity = '0';
  window.setTimeout(() => {
  sideMenu.style.visibility = 'hidden';
  }, 300);
});